"use client";

import { useEffect } from "react";

import { SingleApplicationCardInfoSkeleton } from "@/components/molecules/SingleApplicationCardInfo";
import { AppDetailsSkeleton } from "@/components/organisms/AppDetails";
import { BenefitsSkeleton } from "@/components/organisms/Benefits";
import { CarouselApplicationImagesSkeleton } from "@/components/organisms/CarouselApplicationImages";
import useAppPathname from "@/hooks/common/useAppPathname/useAppPathname";

const SingleAppPageLoading = () => {
  const pathname = useAppPathname();

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  return (
    <div className="flex justify-center items-center w-full">
      <div className="flex flex-col max-w-[1280px] gap-5 w-full">
        <SingleApplicationCardInfoSkeleton />
        <CarouselApplicationImagesSkeleton />
        <BenefitsSkeleton />
        <AppDetailsSkeleton />
      </div>
    </div>
  );
};

export default SingleAppPageLoading;
